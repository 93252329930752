/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;

@tailwind components;

@tailwind utilities;

.btn {
  @apply focus:ring-2 focus:ring-opacity-50 focus:outline-none transition duration-150 w-full mt-4 font-poppins text-lg py-2 uppercase rounded-lg;
}

.btn-blue {
  @apply cursor-pointer border border-blue-700 border-solid bg-indigo-stegofy hover:bg-blue-700 focus:ring-blue-600 text-white ; 
}

.btn-default {
    @apply cursor-pointer border border-blue-700 border-solid rounded-lg text-sm text-indigo-stegofy font-poppins focus:outline-none focus:ring-2 hover:bg-indigo-stegofy hover:text-white
} 
.input {
  @apply border-2 focus:outline-none px-3 py-2 my-2 placeholder-gray-500 text-gray-800 text-lg font-medium font-poppins rounded-lg outline-none w-full
}

.textarea {
    @apply border-2 border-gray-200 hover:border-indigo-300 focus:outline-none bg-gray-50 w-full h-32 rounded-lg px-3 py-2 text-lg font-poppins placeholder-gray-500
}

.side-item {
    @apply text-sm font-normal text-gray-400 font-poppins px-10 py-4;
}

.input-field {
    @apply border-2 border-gray-200 hover:border-indigo-300  focus:outline-none bg-gray-50 w-full rounded-lg px-2 py-2 text-sm text-gray-800 font-poppins placeholder-gray-600;
}

.textarea-field {
    @apply border-2 border-gray-200 hover:border-indigo-300 focus:outline-none bg-gray-50 w-full h-24 rounded-lg px-2 py-2 text-sm text-gray-800 font-poppins; 
}

.heading {
   @apply text-4xl font-bold text-gray-700 font-poppins cursor-default
}

.table{
    @apply min-w-full divide-y divide-gray-200
}

.table-head{
    @apply pl-4 py-6 text-left font-semibold text-sm text-gray-400 uppercase tracking-wider
}
/* body{
    zoom: 0.9;       
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    /* -webkit-box-shadow: 0 0 0 30px #d1fae5 inset !important; */
    border-color: #43ff64d9 !important;
}